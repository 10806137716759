.Glitch {
  font-family: 'Montserrat', sans-serif;
  color: #5388B2;
  font-size: 100px;
  position: relative;
}

@keyframes noise-anim-1 {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      $top: random(100);
      $bottom: random(101 - $top);
      clip-path: inset(#{$top}% 0 #{$bottom}%  0);
    }
  }
}

@keyframes noise-anim-2 {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      $top: random(100);
      $bottom: random(101 - $top);
      clip-path: inset(#{$top}% 0 #{$bottom}%  0);
    }
  }
}

.Glitch::after {
  content: attr(data-text);
  position: absolute;
  left: 7px;
  text-shadow: -1px 0 magenta;
  top: 0;
  color: #5388B2;
  background: #282c34;
  overflow: hidden;
  animation: noise-anim-1 20s infinite linear alternate-reverse;
}


.Glitch::before {
  content: attr(data-text);
  position: absolute;
  left: 6px;
  text-shadow: 1px 0 cyan;
  top: 0;
  color: #5388B2;
  background: #282c34;
  overflow: hidden;
  animation: noise-anim-2 1s infinite linear alternate-reverse;
}
